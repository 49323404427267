import { Link } from '@mui/material';
import * as React from 'react';
import { useVendorApi } from '../../services/vendor-api';
import ErrorDisplay from '../ErrorDisplay';
import useIntlPlus from '../hooks/IntlPlus';
import OpenInNewButton from '../OpenInNewButton';
import Tile from '../Tile';
import { usePartnerContext } from './PartnerContext';
import { DriveLink } from './types.js';

export interface PartnerSummaryProps {
	editable: boolean;
}


const PartnerSummary: React.FC<PartnerSummaryProps> = ( props ) => {
	const { partner, setPartner } = usePartnerContext();
	const intl = useIntlPlus();
	const handleError = ErrorDisplay();
	const { saveVendor } = useVendorApi( handleError );
	return (

		<Tile
			title={ intl.formatMessage( {
				id: 'vendor_details',
				defaultMessage: 'Partner Details'
			} ) }
			sx={ { height: '100%' } }
			editable={ props.editable }
			readonly={ false }
			data={ partner }
			setData={ setPartner }
			onSave={ saveVendor }
			fields={ [
				{
					id: 'name',
					label: 'name',
					editable: true,
					value: partner?.name,
				},
				{
					id: 'alias',
					label: 'alias',
					readOnly: false,
					value: partner?.alias,
				},
				{
					id: 'email',
					label: 'email',
					readOnly: false,
					value: partner?.email,
				},
				{
					id: 'phone',
					label: 'phone',
					value: partner?.phone,
				},
				{
					id: 'driveFolder',
					label: intl.formatMessage( { id: 'Drive Folder', defaultMessage: 'Drive Folder' } ),
					readOnly: true,
					editable: false,
					formatValue: ()=><></>,
					renderInput: (props: any) => <></>,
					renderChildren: ( props: DriveLink ) => {
						const driveFolder = partner?.driveFolder;
						return ( driveFolder &&
					<>
						<Link href={ driveFolder.href } title={driveFolder.label}
							target="_blank">{ driveFolder.label }</Link>
						<OpenInNewButton
							href={ driveFolder.href }
							title={ driveFolder.label }

						/>
					</>
						);
					}
				},
				{
					id: 'reportFolder',
					label: intl.formatMessage( { id: 'partner.report.folder', defaultMessage: 'Latest Community Reports' } ),
					readOnly: true,
					editable: false,
					formatValue: ()=><></>,
					renderInput: (props: any) => <></>,
					renderChildren: ( props: DriveLink ) => {
						const reportFolder = partner?.reportFolder;
						return ( reportFolder &&
					<>
						<Link href={ reportFolder.href } title={ reportFolder.label }
							target="_blank">{ reportFolder.label }</Link>
						<OpenInNewButton
							href={ reportFolder.href }
							title={ reportFolder.label }
						/>
					</>
						);
					}
				},
			] }

		/>
	);
};
export default PartnerSummary;
