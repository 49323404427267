import {
	getDay,
	getWeek,
	subDays
} from 'date-fns';

export const convertBase64ToBlob = (base64Data: string): Blob => {
	const contentType = base64Data.match(/^data:(.*);/)![1];
	const byteCharacters = atob(base64Data.split(',')[1]);
	const byteArrays: Uint8Array[] = [];
	for (let offset = 0; offset < byteCharacters.length; offset += 512) {
		const slice = byteCharacters.slice(offset, offset + 512);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	return new Blob(byteArrays, { type: contentType });
};

export const getModifiedValues = <T extends object>(newRecord:T, oldRecord: T): Partial<T> => {
	const modifiedValues: Partial<T> = {};

	for (const key in newRecord) {
		if (Object.hasOwn(newRecord, key) && Object.hasOwn(oldRecord, key)) {
			if (newRecord[key] !== oldRecord[key]) {
				modifiedValues[key] = newRecord[key];
			}
		}
	}
	return modifiedValues;
};

// Convert URLSearchParams to a standard object
export const convertSearchParamsToObject = (searchParams: URLSearchParams)=> {
	const result: Record<string, string> = {};
	for (const [key, value] of searchParams.entries()) {
		result[key] = value;
	}
	return result;
};

/**
 * Given a date, get date of the previous Thursday
 * TODO: refactor to make this getReportingDate where the DOW is configurable.
 *
 * @param date
 */
export const getPreviousThursday = (date: Date) => {
	const currentDayOfWeek = getDay(date);
	const daysToSubtract = (currentDayOfWeek + 7 - 4) % 7; // 4 represents Thursday
	return subDays( date, daysToSubtract );
};

/**
 * Returns true if week value of date1 is after date2
 * @param date1
 * @param date2
 */
export const isWeekAfter = (date1: Date, date2: Date) => {
	return (getWeek( date1 ) >= getWeek( date2 ));
};


export const createCsvDownload = ( data: string, name: string ) => {
	const blob = new Blob( [ data ], { type: 'text/csv;charset=utf-8;' } );
	let url = URL.createObjectURL( blob );
	let a = document.createElement( 'a' );
	a.href = url;
	a.download = name;
	document.body.appendChild( a );
	a.click();
	a.remove();
}
