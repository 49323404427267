import {
	Print,
	Publish,
	Refresh,
	Unpublished,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import React, {
	SetStateAction,
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Typography,
	DialogProps,
	Fade,
	LinearProgress,
	TextField,
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { useReportingApi } from '../../services/reporting-ui-api';
import AuditInfo from '../AuditInfo';
import ErrorDisplay from '../ErrorDisplay';
import useIntlPlus from '../hooks/IntlPlus';
import { ReportContext } from './ReportContext.js';
import { ReportStateChip } from './ReportStateChip';
import {
	ReportAction,
	ReportState
} from './types';

export interface ReportDialogContentProps {
	templateSpec?: {
		type: string,
		name: string,
		templateVersion?: number,
	},
	initialState : ReportState,
	onChange: any,
	canUpdate: boolean,
	refresh?: boolean
}

export interface ReportDialogProps extends DialogProps {
	initialState : ReportState,
	component: React.FC<ReportDialogContentProps>
	on?: (state: ReportState, event: ReportAction) => void
	canUpdate?: boolean,
	canDelete?: boolean,
	canPublish?: boolean
	enablePrint?: boolean
}

const ReportDialog: React.FC<ReportDialogProps> = ( {
	open,
	onClose,
	title,
	initialState,
	component: Component,
	on = async ()=>{ return; },
	canUpdate = true,
	canDelete = true,
	canPublish = true,
	enablePrint = false,
}) => {
	const confirm = useConfirm();
	const handleError = ErrorDisplay();
	const reportingApi = useReportingApi(handleError);
	const componentRef = useRef(null);
	const { enqueueSnackbar } = useSnackbar();
	const { formatDate } = useIntlPlus();
	if (title && !initialState?.title) {
		initialState.title = title;
	}
	const [reportState, setReportState, ] = useState<ReportState>(initialState);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [modified, setModified] = useState<boolean>(false);
	const auditNote = useRef( '' );
	const updateState = useCallback((newState: Partial<ReportState>) => {
		const setStateAction: SetStateAction<ReportState> = (prevState: ReportState): ReportState => {
			setModified(true);
			const newData = newState.data ? { ...prevState.data, ...newState.data } : prevState.data;
			return {
				...prevState,
				...newState,
				data: newData,
			};
		};

		// Pass the callback function directly, not its return value
		return setReportState(setStateAction);
	},[setModified]);

	const handleSave = useCallback(async ()=>{
		const newState = await reportingApi.saveReport( reportState );
		newState && setReportState(newState);
		await on(newState, 'save');
		setModified(false);
		enqueueSnackbar('Report Saved.', {
			variant:'success',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			}
		});
	},[enqueueSnackbar, on, reportState, reportingApi]);

	const handleClose = useCallback((event:any)=>{
		setReportState(initialState);
		onClose && onClose(event,  'backdropClick');
	}, [initialState, onClose]);

	const handleDelete = useCallback(async (event:any)=>{
		confirm( {
			title: 'Delete Report',
			description: `Delete ${ reportState.title }?`,
			content: <div>
				<Typography>Are you sure you want to delete { title }?</Typography>
				<TextField
					label="Save Note"
					helperText="Enter an optional note."
					variant="outlined"
					fullWidth
					margin="normal"
					multiline
					rows={ 4 } // Adjust number of rows as needed
					onChange={ ( event ) => {
						auditNote.current = ( event.target.value );
					} }
				/></div>
		} ).then( async () => {
			const newState = await reportingApi.deleteReport( reportState );
			setModified(false);
			newState && setReportState(newState);
			await on( newState, 'delete' );
			handleClose(event);
			return enqueueSnackbar('Report Deleted.', {
				variant:'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center'
				}
			});
		}).catch((error)=>error && console.error(error));
	},[enqueueSnackbar, handleClose, on, reportState, reportingApi]);

	const handleSubmit = async ()=>{
		const newState = await reportingApi.submitReport( reportState );
		setModified(false);
		newState?.id && setReportState(newState);
		await on(newState, 'submit');
		enqueueSnackbar('Report Submitted.', {
			variant:'success',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			}
		});
	};

	const handleRetract = async ()=>{
		const newState = await reportingApi.retractReport( reportState );
		setModified(false);
		newState?.id && setReportState(newState);
		await on(newState, 'retract');
		enqueueSnackbar('Report Retracted.', {
			variant:'success',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			}
		});
	};
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onPrintError: (errorLocation, error)=>{
			enqueueSnackbar('An error occurred updating filters.  Please clear or update value and retry.', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center'
				}});
		}
	});

	const handleRefresh = async (event: any)=>  {
		return await confirm({
			// confirmationText: 'Refresh',
			title: 'Refresh',
			description: 'Do you wish to reset and refresh this report?'
		})
			.then(async () => {
				setRefresh(true);
			})
			.catch((reason)=>{});
	};

	useEffect(()=>{
		refresh && setRefresh(false);
	},[refresh]);

	useEffect( () => {
		if (initialState?.id) {
			reportingApi.getReport(initialState?.id)
				.then(res=>setReportState(res));
		}
	}, [initialState?.id] );

	// @ts-ignore
	return (
		<ReportContext.Provider value={[reportState, setReportState]}>
			<Dialog
				ref={componentRef}
				fullScreen
				open={open} onClose={onClose}>
				<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant="h6">{title}</Typography>
					<Typography variant="body2">{formatDate(reportState?.date)}</Typography>
					<Box className="print-hide" sx={{display: 'flex', justifyContent: 'space-between', color: 'text.secondary'}}>
						<ReportStateChip state={ reportState.state } />
						<IconButton disabled={!modified || !canUpdate || reportState?.submitted} onClick={handleSave} title='Save Report'><SaveIcon/></IconButton>
						{ !reportState?.submitted && <IconButton disabled={!canUpdate} onClick={handleRefresh} title='Refresh auto-filled information'><Refresh/></IconButton> }
						{ reportState?.submitted ? <IconButton disabled={!canUpdate} onClick={handleRetract} title='Retract Report Submission'><Unpublished/></IconButton>
							: <IconButton disabled={!canUpdate} onClick={handleSubmit} title='Submit Report' ><Publish/></IconButton>
						}
						<IconButton disabled={!(canDelete && !reportState?.submitted)} onClick={handleDelete} title='Delete Report'><DeleteIcon/></IconButton>
						{ enablePrint && <IconButton onClick={ handlePrint } title={ `Print Weekly ${ title }` }><Print/></IconButton> }
						<AuditInfo auditable={reportState} />
						<IconButton onClick={handleClose} title='Close Report Dialog'><CloseIcon /></IconButton>
					</Box>
				</DialogTitle>
				<Fade in={ reportingApi.isLoading } unmountOnExit><LinearProgress  /></Fade>
				<DialogContent dividers >
					{ Component && <Component initialState={ reportState } onChange={ updateState } canUpdate={canUpdate} refresh={refresh} /> }
				</DialogContent>
			</Dialog>
		</ReportContext.Provider>
	);
};

export default ReportDialog;
